import { GetProductsRequest } from '@yiluhub/yilu-amp-types';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { convertToBase64 } from 'utils/base64Utils';

import { EmbeddedEventType, useEmbeddedTIContext } from 'context/EmbeddedTI';

import { useAmpCalls } from './useAmpCalls';

type MeiliSearchParameters = {
  pickupLocation: {
    iataCode: string;
  };
  dropoffLocation: {
    iataCode: string;
  };
  pickupDate: string;
  pickupTime: string;
  dropoffDate: string;
  dropoffTime: string;
  driverAge: string;
  countryCode?: string;
  currencyCode: string;
};

type MeiliWidgetEvent = {
  type: string;
  detail: {
    type: string;
    query: string;
  };
};

type LocationState = {
  shoppingCartId: string;
  searchParams: GetProductsRequest['query'];
};

const MEILI_SCRIPT_ID = 'meili-script';
const MEILI_CONNECT_LISTENER = 'MEILI_CONNECT_LISTENER';

enum MEILI_EVENT {
  MEILI_EVENT_SELECTION_ADD = 'MEILI_EVENT_SELECTION_ADD',
  MEILI_EVENT_SELECTION_REMOVE = 'MEILI_EVENT_SELECTION_REMOVE',
}

export const useCarRentalPage = () => {
  const location = useLocation();
  const state = location.state as LocationState;
  const { sendPostMessage } = useEmbeddedTIContext();
  const [isBookingIntentCreated, setIsBookingIntentCreated] = useState(false);
  const [isItemInShoppingCart, setIsItemInShoppingCart] = useState(false);
  const [shoppingCartId, setShoppingCartId] = useState('');
  const [isDataLoading, setIsDataLoading] = useState(true);
  const { addItemToShoppingCart, createBookingIntent, emptyShoppingCart, getShoppingCart } =
    useAmpCalls();

  const injectScript = useCallback(() => {
    // Avoid multiple injections
    if (document.getElementById(MEILI_SCRIPT_ID)) {
      return;
    }

    const script = document.createElement('script');
    script.id = MEILI_SCRIPT_ID;
    script.src = 'https://uat-connect-ux.meili.travel/index.js';
    script.type = 'text/javascript';

    document.body.appendChild(script);
  }, []);

  const initialScriptLoad = useCallback(
    async (meiliParams: MeiliSearchParameters[]) => {
      let shoppingCart;
      try {
        shoppingCart = await getShoppingCart(state.shoppingCartId);
      } catch (e) {
        console.log(e);
      } finally {
        // if shopping cart is not empty then clear meili state
        setIsDataLoading(false);
        if (shoppingCart?.data.cartItems.length > 0) {
          setIsItemInShoppingCart(true);
        } else {
          localStorage.removeItem('meili-connect-state');
        }
        createMeiliContainer(meiliParams);
        injectScript();
      }
    },
    [shoppingCartId, state],
  );

  const removeScript = useCallback(() => {
    const script = document.getElementById(MEILI_SCRIPT_ID);
    if (script) {
      document.body.removeChild(script);
    }
  }, [state]);

  useEffect(() => {
    if (!state) return;

    const [pickupDate, pickupTime] = state.searchParams.startAt!.split('T');
    const [dropoffDate, dropoffTime] = state.searchParams.endAt!.split('T');

    const meiliSearchParameters: MeiliSearchParameters[] = [
      {
        pickupLocation: { iataCode: state.searchParams.from! },
        dropoffLocation: { iataCode: state.searchParams.to! },
        pickupDate: pickupDate,
        pickupTime: pickupTime,
        dropoffDate: dropoffDate,
        dropoffTime: dropoffTime,
        driverAge: '30',
        countryCode: state.searchParams.travelerResidency,
        currencyCode: state.searchParams.currency!,
      },
    ];

    setShoppingCartId(state.shoppingCartId);
    initialScriptLoad(meiliSearchParameters);

    return () => {
      removeScript();
    };
  }, [state, emptyShoppingCart, removeScript, initialScriptLoad]);

  const createMeiliContainer = useCallback((searchParams: MeiliSearchParameters[]) => {
    const encodedData = convertToBase64(searchParams);
    const mliRootDiv = document.getElementById('mli-root');
    if (mliRootDiv) {
      mliRootDiv.setAttribute('data-query', encodedData);
    }
  }, []);

  const handleMeiliEventSelectionAdd = useCallback(
    async (meiliEvent: MeiliWidgetEvent) => {
      if (!shoppingCartId) return;
      window.scrollTo(0, 0);
      // NOTE: Error cases need to be handled properly here ( from UX perspective )
      const response = await addItemToShoppingCart(shoppingCartId, meiliEvent.detail.query);
      if (response?.status === 201) {
        const data = await createBookingIntent(shoppingCartId);
        if (data && data.bookingIntentId) {
          setIsBookingIntentCreated(true);
        }
      }
    },
    [shoppingCartId, addItemToShoppingCart, createBookingIntent],
  );

  const handleMeiliEventSelectionRemove = useCallback(async () => {
    if (!shoppingCartId) return;
    setIsBookingIntentCreated(false);
    await emptyShoppingCart(shoppingCartId);
    sendPostMessage(EmbeddedEventType.EMBEDDED_TI_CAR_RENTAL_REMOVED, undefined);
  }, [shoppingCartId, emptyShoppingCart]);

  const handleOnCancelButtonClick = useCallback(async () => {
    if (!shoppingCartId) return;
    setIsBookingIntentCreated(false);
    try {
      // this indicates if the shopping cart had items added to it before the page load
      if (!isItemInShoppingCart) {
        await emptyShoppingCart(shoppingCartId);
        localStorage.removeItem('meili-connect-state');
      }
    } catch (e) {
      console.log('clearing shopping cart failed:', e);
    } finally {
      sendPostMessage(EmbeddedEventType.EMBEDDED_TI_EXIT, undefined);
    }
  }, [shoppingCartId, isItemInShoppingCart, emptyShoppingCart, sendPostMessage]);

  const handleOnConfirmButtonClick = useCallback(() => {
    sendPostMessage(EmbeddedEventType.EMBEDDED_TI_SHOPPING_CART_UPDATED, undefined);
  }, [sendPostMessage]);

  useEffect(() => {
    window.addEventListener(
      MEILI_CONNECT_LISTENER,
      async (event: Event) => {
        const meiliEvent = event as unknown as MeiliWidgetEvent;
        switch (meiliEvent.detail.type) {
          case MEILI_EVENT.MEILI_EVENT_SELECTION_ADD: {
            handleMeiliEventSelectionAdd(meiliEvent);
            break;
          }
          case MEILI_EVENT.MEILI_EVENT_SELECTION_REMOVE: {
            handleMeiliEventSelectionRemove();
            break;
          }
          default:
            break;
        }
      },
      false,
    );
  }, [handleMeiliEventSelectionAdd, handleMeiliEventSelectionRemove]);

  return {
    isDataLoading,
    isBookingIntentCreated,
    isItemInShoppingCart,
    handleOnCancelButtonClick,
    handleOnConfirmButtonClick,
  };
};
