/**
 * Simpletrip Travel Integration
 *
 * Entry point of rendering the application
 */
import 'regenerator-runtime';
import routes from 'router/routes';

import { YILU_OVERRIDE_KEY, renderYiluApp, yiluEnv } from './index.wrapper';

yiluEnv.setVariables({
  YILU_API_PRODUCTION_ENVIRONMENT: import.meta.env.VITE_YILU_API_PRODUCTION_ENVIRONMENT,
  GOOGLE_MAPS_KEY: import.meta.env.VITE_GOOGLE_MAPS_KEY,
  GOOGLE_ANALYTICS_MEASUREMENT_ID: import.meta.env.VITE_GOOGLE_ANALYTICS_MEASUREMENT_ID,
  GOOGLE_TAG_MANAGER_CONTAINER_ID: import.meta.env.VITE_GOOGLE_TAG_MANAGER_CONTAINER_ID,
  HOTJAR_ID: import.meta.env.VITE_HOTJAR_ID,

  YILU_AMP_BACKEND_URL: import.meta.env.VITE_YILU_AMP_BACKEND_URL,
  YILU_AMP_STORE_ID: import.meta.env.VITE_YILU_AMP_STORE_ID,
  YILU_AMP_LOUNGE_VERTICAL_ID: import.meta.env.VITE_YILU_AMP_LOUNGE_VERTICAL_ID,
  YILU_AMP_PARKING_VERTICAL_ID: import.meta.env.VITE_YILU_AMP_PARKING_VERTICAL_ID,
  YILU_AMP_AIRPORT_TRANSFER_VERTICAL_ID:
    import.meta.env.VITE_YILU_AMP_AIRPORT_TRANSFER_VERTICAL_ID,
  YILU_AMP_AIRPORT_TRANSFER_PASSES_VERTICAL_ID:
    import.meta.env.VITE_YILU_AMP_AIRPORT_TRANSFER_PASSES_VERTICAL_ID,
  YILU_AMP_RIDES_VERTICAL_ID: import.meta.env.VITE_YILU_AMP_RIDES_VERTICAL_ID,
  YILU_AMP_CAR_RENTAL_VERTICAL_ID: import.meta.env.VITE_YILU_AMP_CAR_RENTAL_VERTICAL_ID,
  YILU_AMP_LOUNGE_CATALOG_ID: import.meta.env.VITE_YILU_AMP_LOUNGE_CATALOG_ID,
  YILU_AMP_MEILI_CATALOG_ID: import.meta.env.VITE_YILU_AMP_MEILI_CATALOG_ID,

  LOCALES_HOST_URL: import.meta.env.VITE_LOCALES_HOST_URL,
  PHONE_COUNTRY_CODE: import.meta.env.VITE_PHONE_COUNTRY_CODE,
  SBB_CURRENCY: import.meta.env.VITE_SBB_CURRENCY,
  YILU_ENTERPRISE_PARTNER: import.meta.env.VITE_YILU_ENTERPRISE_PARTNER,
  ABTEST_ENABLED: import.meta.env.VITE_ABTEST_ENABLED,
  YILU_EMBEDDED_TI: import.meta.env.VITE_YILU_EMBEDDED_TI,
  YILU_MEILI_PTID: import.meta.env.VITE_YILU_MEILI_PTID,
  YILU_MEILI_MANAGE_PTID: import.meta.env.VITE_YILU_MEILI_MANAGE_PTID,
  YILU_LANDING_PAGE_ENABLED: import.meta.env.VITE_YILU_LANDING_PAGE_ENABLED,
  YILU_MEILI_MANAGE_ENABLED: import.meta.env.VITE_YILU_MEILI_MANAGE_ENABLED,

  HIGHPASS_API_KEY: import.meta.env.VITE_HIGHPASS_API_KEY,
  HIGHPASS_STYLES: {
    backgroundColor: '#ffffff',
    buttonColor: '#FFAD00',
    inputColor: '#F8F9FD',
    linkColor: '#ac1804',
    headingColor: '#05164D',
    defaultTextColor: '#05164D',
    errorColor: '#FF0000',
    serviceDescriptionColor: '#05164D',
    labelColor: '#333333',
    importantColor: '#05164D',
    headerTextColor: '#05164D',
    descriptionTextColor: '#05164D',
  },
});

renderYiluApp({
  overrides: {
    // example of how it can be used
    [YILU_OVERRIDE_KEY.YILU_HEADER_LOGO]: (originalProps, OriginalComponent) => {
      return <OriginalComponent {...originalProps} />;
    },
  },
  defaultPath: routes.LOUNGES_SEARCH,
  assetPathOverrides: {
    parking: {
      sipImagePaths: ['https://placehold.co/290x180?text=Parking+1'],
    },
    lounges: {
      sipImagePaths: [null, null, 'https://placehold.co/290x180?text=Lounge+3'],
    },
    airportTransfer: {
      sipImagePaths: [null, 'https://placehold.co/290x180?text=Bus/Train+2', null],
    },
    rides: {
      sipImagePaths: [
        'https://placehold.co/290x180?text=Rides+1',
        'https://placehold.co/290x180?text=Rides+2',
        'https://placehold.co/290x180?text=Rides+3',
      ],
    },
  },
});
