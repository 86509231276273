import React from 'react';

import { getVariables } from 'utils/yiluEnv';

import { useCarRentalManagePage } from 'modules/meili/hooks/useCarRentalManagePage';

import './styles.scss';

const CarRentalManagePage: React.FC = () => {
  const { YILU_MEILI_MANAGE_PTID } = getVariables();
  useCarRentalManagePage();

  return (
    <>
      <div id="mli-root" className="mli-loading" data-ptid={YILU_MEILI_MANAGE_PTID}></div>
    </>
  );
};
export default CarRentalManagePage;
