import { useCallback, useEffect } from 'react';

const MEILI_SCRIPT_ID = 'meili-script';

export const useCarRentalManagePage = () => {
  const injectScript = useCallback(() => {
    // Avoid multiple injections
    if (document.getElementById(MEILI_SCRIPT_ID)) {
      return;
    }

    const script = document.createElement('script');
    script.id = MEILI_SCRIPT_ID;
    script.src = 'https://uat-booking-ux.meili.travel/index.js';
    script.type = 'text/javascript';

    document.body.appendChild(script);
  }, []);

  const initialScriptLoad = useCallback(async () => {
    injectScript();
  }, []);

  const removeScript = useCallback(() => {
    const script = document.getElementById(MEILI_SCRIPT_ID);
    if (script) {
      document.body.removeChild(script);
    }
  }, []);

  useEffect(() => {
    initialScriptLoad();

    return () => {
      removeScript();
    };
  }, [removeScript, initialScriptLoad]);
};
