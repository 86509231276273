import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getVariables } from 'utils/yiluEnv';

import { useCarRentalPage } from 'modules/meili/hooks/useCarRentalPage';

import './styles.scss';

const CarRentalPage: React.FC = () => {
  const { YILU_MEILI_PTID } = getVariables();
  const { t } = useTranslation();
  const {
    isDataLoading,
    isBookingIntentCreated,
    isItemInShoppingCart,
    handleOnCancelButtonClick,
    handleOnConfirmButtonClick,
  } = useCarRentalPage();

  return (
    <>
      <div
        id="mli-root"
        data-ptid={YILU_MEILI_PTID}
        data-path="inPath"
        data-fullpage="true"
        data-initial-view="results"
      ></div>
      <div className="yilu-embedded-ti-call-to-actions">
        {!isDataLoading && (
          <ElementsSDK.Button size="medium" outline={true} onClick={handleOnCancelButtonClick}>
            {isItemInShoppingCart && t('embedded-ti.back')}
            {!isItemInShoppingCart && t('embedded-ti.cancel')}
          </ElementsSDK.Button>
        )}
        <div className="yilu-embedded-ti-cta-container">
          {isBookingIntentCreated && (
            <ElementsSDK.Button size="medium" onClick={handleOnConfirmButtonClick}>
              {t('embedded-ti.confirm')}
            </ElementsSDK.Button>
          )}
        </div>
      </div>
    </>
  );
};
export default CarRentalPage;
