/**
 * # Search Result Card - Parking
 *
 *
 */
import { IconProps } from '@yiluhub/ui-elements/dist/types/icons/types';
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { formatCurrency, getDuration, useIsDesktop } from '@yiluhub/ui-utilities';
import { ParkingTransferType, SearchItem } from '@yiluhub/yilu-amp-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchResultCard } from 'components';

import {
  findListItemWithCancellation,
  resolveParkingTypeText,
  resolveTimeToPoiText,
  resolveTransferFrequencyText,
} from 'modules/parking/utils/distanceUtils';
import { ParkingProviderId, getParkingDetails } from 'modules/parking/utils/getParkingDetails';

import { TravellingIcon } from '../../../utils/constants';
import './styles.scss';

export type ParkingSearchResultCardProps = {
  data: SearchItem;
  onPdpClick?(data: SearchItem): unknown;
  onTipClick?(data: SearchItem): unknown;
  cardRef?: React.Dispatch<React.SetStateAction<HTMLDivElement | null>> | undefined;
};

const MAX_STAR_RATING = 5;

const travellingIconMapping: Record<TravellingIcon, React.FunctionComponent<IconProps>> = {
  WALK: ElementsSDK.Icon.Walking,
  BUS: ElementsSDK.Icon.Bus,
  TRAIN: ElementsSDK.Icon.Train,
};

export const ParkingSearchResultCard: React.FC<ParkingSearchResultCardProps> = ({
  data,
  onPdpClick,
  onTipClick,
  cardRef,
}) => {
  const { t } = useTranslation();
  const { isDesktop } = useIsDesktop();

  const parkingData = data;

  const onPdpClickCb = useCallback(() => {
    onPdpClick && onPdpClick(parkingData);
  }, [onPdpClick, parkingData]);

  const onTipClickCb = useCallback(() => {
    onTipClick && onTipClick(parkingData);
  }, [onTipClick, parkingData]);
  const parkingDetails = getParkingDetails(parkingData);

  const {
    providerId,
    price,
    currency,
    walkingDistanceTime,
    productTypeCode,
    carParkType,
    walkingDistance,
    productDescription,
    travellingIcon,
    transferTime,
    transferType,
    transferFrequencyByShuttle,
    rating,
    name,
    logo,
    address,
    features,
    policy,
    arrival,
    departure,
    airportName,
    onAirport,
    meetAndGreet,
    iata,
  } = parkingDetails;

  const isParkVia = providerId === ParkingProviderId.PARK_VIA;
  const isParkAero = providerId === ParkingProviderId.PARK_AERO;
  const isHolidayExtras = providerId === ParkingProviderId.HOLIDAY_EXTRAS;

  const atAirport = onAirport || meetAndGreet;

  const cancellationPolicy = productDescription
    ? findListItemWithCancellation(productDescription)
    : undefined;

  const timeToPOI = resolveTimeToPoiText({
    t: t,
    transferTime: transferTime,
    walkingDistance: walkingDistance,
    airportName: airportName || t('Airport'),
  });

  const normalizedFeatures = features?.map((feature) => feature.toLowerCase());
  const isMeetAndGreet =
    normalizedFeatures?.includes('meet and greet') ||
    normalizedFeatures?.includes('valetparken') ||
    transferType === ParkingTransferType.MEET_AND_GREET ||
    meetAndGreet;

  // transferType is not available for some providers, so we need to check if it's a walk transfer
  const isWalk = !!transferType && transferType === ParkingTransferType.WALK;

  const transferFrequencyText =
    !isWalk &&
    resolveTransferFrequencyText(
      t,
      productTypeCode,
      transferTime,
      transferFrequencyByShuttle,
      isMeetAndGreet,
    );

  const parkingTypeText = resolveParkingTypeText(t, carParkType);

  const showRetrievedCancellationPolicy = isParkAero && cancellationPolicy && isDesktop;

  const showCancellationPolicy =
    (isParkVia && policy.termsUrl) ||
    (isParkAero && (!isDesktop || !cancellationPolicy) && policy.termsUrl) ||
    (isHolidayExtras && policy.termsLabel && policy.termsLabel !== '');

  const isFra = iata === 'FRA';

  const hasRating = typeof rating === 'number';
  const is24Hours =
    normalizedFeatures?.includes('24 hour') || normalizedFeatures?.includes('24 stunden geöffnet');
  const duration = getDuration(arrival, departure);
  const roundedDurationDays = duration.days + (duration.hours || duration.minutes ? 1 : 0);
  const TransferFrequencyIcon =
    transferFrequencyText === t('No shuttle service')
      ? ElementsSDK.Icon.Cross
      : transferFrequencyText === t('search.result.card.parking.meet.and.greet')
      ? ElementsSDK.Icon.HandShake
      : ElementsSDK.Icon.Bus;

  const _travellingIcon = travellingIcon || (isWalk && TravellingIcon.WALK);

  const TravellingIconComp = _travellingIcon && travellingIconMapping?.[_travellingIcon];

  // Show address if it's ParkVia or at the airport. At the airport will set the address as "handover at terminal"
  const showAddress = (isParkVia || atAirport) && !timeToPOI && address;

  function renderCancellationPolicy(policy: {
    termsUrl?: string;
    termsLabel?: string;
  }): JSX.Element | string | undefined {
    if (isParkVia || isParkAero) {
      return (
        <ElementsSDK.Link
          className="yilu-Parking__ListItemLink"
          href={policy.termsUrl}
          target="_blank"
          rel="noreferrer"
        >
          {t('search.result.card.parking.cancellation.policy')}
        </ElementsSDK.Link>
      );
    }

    if (isHolidayExtras) {
      return policy.termsLabel;
    }

    return undefined;
  }

  return (
    <SearchResultCard className="yilu-SearchResultItem__parking" ref={cardRef}>
      {isParkAero && !isFra && (
        <ElementsSDK.Chip
          type="miles"
          className="yilu-Parking__MilesBadge"
          text={t('search.result.card.parking.miles')}
        />
      )}
      {isParkAero && isFra && (
        <ElementsSDK.Chip
          type="miles"
          className="yilu-Parking__MilesBadge"
          text={t('search.result.card.parking.miles.fra')}
        />
      )}
      <div className="yilu-Parking">
        {logo ? (
          <img src={logo} alt={t('Provider logo')} className="yilu-Parking__ProviderLogo" />
        ) : (
          <div />
        )}
        <ElementsSDK.Typography variant="h3" className="yilu-Parking__Header">
          {name}
        </ElementsSDK.Typography>
        {hasRating && rating && (
          <ElementsSDK.Rating
            maxRating={MAX_STAR_RATING}
            rating={rating}
            showNumbers
            className="yilu-Parking__Rating"
          />
        )}
        {isParkAero && (
          <ElementsSDK.Chip
            className="yilu-Parking__Chip"
            type="active"
            text={t('search.result.card.parking.chip')}
          />
        )}
        <ul className="yilu-Parking__Info">
          {showAddress && (
            <li className="yilu-Parking__ListItem">
              <ElementsSDK.Icon.Pin className="yilu-Parking__FeatureIcon" />
              <ElementsSDK.Typography size="medium" title={address}>
                <span dangerouslySetInnerHTML={{ __html: address }}></span>
              </ElementsSDK.Typography>
            </li>
          )}
          {!isParkVia && timeToPOI && (
            <li className="yilu-Parking__ListItem">
              <ElementsSDK.Icon.Pin className="yilu-Parking__FeatureIcon" />
              <ElementsSDK.Typography size="medium" title={timeToPOI}>
                <span dangerouslySetInnerHTML={{ __html: timeToPOI }}></span>
              </ElementsSDK.Typography>
            </li>
          )}

          {TravellingIconComp && walkingDistanceTime && (
            <li className="yilu-Parking__ListItem">
              <TravellingIconComp className="yilu-Parking__FeatureIcon" />
              <ElementsSDK.Typography size="medium">
                {walkingDistanceTime + ' min'}
              </ElementsSDK.Typography>
            </li>
          )}
          {transferFrequencyText && (
            <li className="yilu-Parking__ListItem" data-testid="transferFrequencyText">
              <TransferFrequencyIcon className="yilu-Parking__FeatureIcon" />
              <ElementsSDK.Typography size="medium">{transferFrequencyText}</ElementsSDK.Typography>
            </li>
          )}
          {carParkType && (
            <li className="yilu-Parking__ListItem" data-testid="carParkType">
              <ElementsSDK.Icon.Parking className="yilu-Parking__FeatureIcon" />
              <ElementsSDK.Typography size="medium">{parkingTypeText}</ElementsSDK.Typography>
            </li>
          )}
          {is24Hours && (
            <li className="yilu-Parking__ListItem" data-testid="24hours">
              <ElementsSDK.Icon.Time className="yilu-Parking__FeatureIcon" />
              <ElementsSDK.Typography size="medium">
                {t('search.result.card.parking.24.hours')}
              </ElementsSDK.Typography>
            </li>
          )}
          {showRetrievedCancellationPolicy && (
            <li className="yilu-Parking__ListItem" data-testid="cancellationPolicy">
              <ElementsSDK.Icon.Info className="yilu-Parking__FeatureIcon" />
              <ElementsSDK.Typography size="medium">{cancellationPolicy}</ElementsSDK.Typography>
            </li>
          )}
          {showCancellationPolicy && (
            <li className="yilu-Parking__ListItem">
              <ElementsSDK.Icon.Info className="yilu-Parking__FeatureIcon" />
              <ElementsSDK.Typography size="medium">
                {renderCancellationPolicy(policy)}
              </ElementsSDK.Typography>
            </li>
          )}
        </ul>
      </div>
      <div className="yilu-Parking__Footer">
        <div className="yilu-Parking__FooterLeft">
          <ElementsSDK.Typography size="medium" className="yilu-Parking__TotalDays" block>
            {t('search.result.card.parking.footer.days', {
              days: roundedDurationDays,
            })}
          </ElementsSDK.Typography>
          <ElementsSDK.Typography
            className="yilu-Parking__Price"
            size="extraLarge"
            bold
            block
            translate="no"
          >
            {formatCurrency(price, currency)}
          </ElementsSDK.Typography>
        </div>
        <div className="yilu-Parking__FooterRight">
          <ElementsSDK.Button
            className="yilu-Parking__FooterButton"
            testid="parking.card.pdp.button"
            outline
            inverted={false}
            size="small"
            onClick={onPdpClickCb}
          >
            {t('search.result.card.parking.pdp.button')}
          </ElementsSDK.Button>
          <ElementsSDK.Button
            className="yilu-Parking__FooterButton"
            testid="parking.card.tip.button"
            onClick={onTipClickCb}
            size="small"
          >
            {t('search.result.card.parking.tip.button')}
          </ElementsSDK.Button>
        </div>
      </div>
    </SearchResultCard>
  );
};
