import { formatDistance } from '@yiluhub/ui-utilities';
import { TFunction } from 'i18next';

import { CarparkType, ParkingType } from './constants';

export const resolveTimeToPoiText = ({
  t,
  transferTime,
  walkingDistance,
  airportName,
}: {
  t: TFunction;
  transferTime?: string | null;
  walkingDistance?: number | null;
  airportName?: string;
}) => {
  let timeToPOI: string | undefined;

  if (walkingDistance) {
    timeToPOI = t('distanceToPOI', {
      address: airportName,
      distanceToPOI: formatDistance(walkingDistance),
    });
  } else if (transferTime) {
    timeToPOI = t('timeToPOI', {
      address: airportName,
      transferTimeByShuttle: transferTime,
    });
  }

  return timeToPOI;
};

export const resolveTransferFrequencyText = (
  t: TFunction,
  productTypeCode?: ParkingType | string,
  transferTime?: string | null,
  transferFrequencyByShuttle?: string | null,
  isMeetAndGreet?: boolean,
): string | undefined => {
  let transferFrequencyText: string | undefined;
  const isAirportType = productTypeCode === ParkingType.AIRPORT;

  if (transferTime) {
    transferFrequencyText = t('transferFrequency', {
      transferFrequency: transferTime,
    });
  } else if (transferFrequencyByShuttle) {
    const normalizedTransferFrequency = transferFrequencyByShuttle.toLowerCase();
    transferFrequencyText = t('transferFrequency', {
      transferFrequency: normalizedTransferFrequency,
    });
  } else if (isMeetAndGreet) {
    transferFrequencyText = t('search.result.card.parking.meet.and.greet');
  } else if (!transferTime && isAirportType) {
    transferFrequencyText = t('No shuttle service');
  }
  return transferFrequencyText;
};

export const resolveParkingTypeText = (t: TFunction, carparkType?: CarparkType) => {
  switch (carparkType) {
    case CarparkType.GARAGE:
      return t('parking.location.type.garage');
    case CarparkType.UNDERGROUND:
      return t('parking.location.type.underground');
    default:
      return t('parking.location.type.outdoor');
  }
};

export const findListItemWithCancellation = (htmlString: string): string | undefined => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  const listItems = doc.querySelectorAll('ul li');

  for (let i = 0; i < listItems.length; i++) {
    const listItem = listItems[i];
    const textContent = listItem.textContent || '';

    if (
      textContent.toLowerCase().includes('cancellation') ||
      textContent.toLowerCase().includes('stornierung')
    ) {
      return textContent;
    }
  }

  return undefined;
};
