export enum PRODUCT_ROUTE {
  AIRPORT_TRANSFER = 'airport-transfer',
  RIDES = 'rides',
  PARKING = 'parking',
  LOUNGES = 'lounges',
  SWISS_TRAVEL_PASS = 'airport-transfer/swiss-travel',
  HIGHPASS_FAST_TRACK = 'fasttrack/product',
  HIGHPASS_LOUNGES = 'highpass-lounges',
  HIGHPASS_VIP_SERVICE = 'highpass-vip',
  HIGHPASS_MEET_GREET = 'highpass-meet-and-greet',
  MEILI = 'meili',
  MEILI_MANAGE = 'manage',
}

const routes = {
  BASE: '/',

  AIRPORT_TRANSFER_SEARCH: `/${PRODUCT_ROUTE.AIRPORT_TRANSFER}/search`,
  AIRPORT_TRANSFER_SEARCH_RESULTS: `/${PRODUCT_ROUTE.AIRPORT_TRANSFER}/results`,
  AIRPORT_TRANSFER_PDP: `/${PRODUCT_ROUTE.AIRPORT_TRANSFER}/product`,
  AIRPORT_TRANSFER_TIP: `/${PRODUCT_ROUTE.AIRPORT_TRANSFER}/booking/passengers`,
  AIRPORT_TRANSFER_PIP: `/${PRODUCT_ROUTE.AIRPORT_TRANSFER}/booking/pay`,
  AIRPORT_TRANSFER_BCP: `/${PRODUCT_ROUTE.AIRPORT_TRANSFER}/booking/confirmation`,

  SWISS_TRAVEL_PASS_SEARCH: `/${PRODUCT_ROUTE.SWISS_TRAVEL_PASS}/search`,
  SWISS_TRAVEL_PASS_TIP: `/${PRODUCT_ROUTE.SWISS_TRAVEL_PASS}/booking/passengers`,
  SWISS_TRAVEL_PASS_PIP: `/${PRODUCT_ROUTE.SWISS_TRAVEL_PASS}/booking/pay`,
  SWISS_TRAVEL_PASS_BCP: `/${PRODUCT_ROUTE.SWISS_TRAVEL_PASS}/booking/confirmation`,

  RIDES_SEARCH: `/${PRODUCT_ROUTE.RIDES}/search`,
  RIDES_SEARCH_RESULTS: `/${PRODUCT_ROUTE.RIDES}/results`,
  RIDES_TIP: `/${PRODUCT_ROUTE.RIDES}/booking/passengers`,
  RIDES_PIP: `/${PRODUCT_ROUTE.RIDES}/booking/pay`,
  RIDES_BCP: `/${PRODUCT_ROUTE.RIDES}/booking/confirmation`,

  PARKING_SEARCH: `/${PRODUCT_ROUTE.PARKING}/search`,
  PARKING_SEARCH_RESULTS: `/${PRODUCT_ROUTE.PARKING}/results`,
  PARKING_PDP: `/${PRODUCT_ROUTE.PARKING}/product`,
  PARKING_TIP: `/${PRODUCT_ROUTE.PARKING}/booking/passengers`,
  PARKING_PIP: `/${PRODUCT_ROUTE.PARKING}/booking/pay`,
  PARKING_BCP: `/${PRODUCT_ROUTE.PARKING}/booking/confirmation`,

  LOUNGES_SEARCH: `/${PRODUCT_ROUTE.LOUNGES}/search`,
  LOUNGES_SEARCH_RESULTS: `/${PRODUCT_ROUTE.LOUNGES}/results`,
  LOUNGES_PDP: `/${PRODUCT_ROUTE.LOUNGES}/product`,
  LOUNGES_TIP: `/${PRODUCT_ROUTE.LOUNGES}/booking/passengers`,
  LOUNGES_PIP: `/${PRODUCT_ROUTE.LOUNGES}/booking/pay`,
  LOUNGES_BCP: `/${PRODUCT_ROUTE.LOUNGES}/booking/confirmation`,

  HIGHPASS_FAST_TRACK: `/${PRODUCT_ROUTE.HIGHPASS_FAST_TRACK}`,
  HIGHPASS_LOUNGES: `/${PRODUCT_ROUTE.HIGHPASS_LOUNGES}`,
  HIGHPASS_VIP_SERVICE: `/${PRODUCT_ROUTE.HIGHPASS_VIP_SERVICE}`,
  HIGHPASS_MEET_GREET: `/${PRODUCT_ROUTE.HIGHPASS_MEET_GREET}`,

  MEILI_CAR_RENTAL: `/car-rental/${PRODUCT_ROUTE.MEILI}`,
  MEILI_CAR_RENTAL_MANAGE: `/car-rental/${PRODUCT_ROUTE.MEILI_MANAGE}`,
};

export default routes;
