import { CreateBookingRequest, CreateBookingResponse } from '@yiluhub/yilu-amp-types';
import axios from 'axios';
import { useCallback } from 'react';

import { getVariables } from 'utils/yiluEnv';

type useCreateBookingParamsType = {
  bookingIntentId: string;
  paymentProviderId: string;
  userId: string;
  onPaymentResults: (response: CreateBookingResponse) => void;
  touchPointId: string;
  decisionId?: string;
};

export const useCreateBooking = ({
  bookingIntentId,
  paymentProviderId,
  userId,
  onPaymentResults,
  touchPointId,
  decisionId,
}: useCreateBookingParamsType) => {
  const createBooking = useCallback(
    async (stripeSetup: stripe.SetupIntentResponse) => {
      const yiluEnv = getVariables();
      const intent = stripeSetup.setupIntent;

      if (stripeSetup && !stripeSetup.error && intent && intent.payment_method) {
        try {
          const ampBookingRequest: CreateBookingRequest['requestBody'] = {
            userId,
            bookingIntentId,
            paymentDetails: {
              tokenId: intent.payment_method,
              provider: paymentProviderId,
            },
            touchPoint: touchPointId,
            bookingMetadata: {
              decisionID_PPFI: decisionId,
            },
          };
          const response = await axios.post<CreateBookingResponse>(
            `${yiluEnv.YILU_AMP_BACKEND_URL}/booking/v1/bookings`,
            ampBookingRequest,
          );

          if (!(response.status === 201 && response.data.id)) {
            throw new Error('Missing payment response id');
          }

          onPaymentResults({
            id: response.data.id,
          });
        } catch (error) {
          throw new Error('Error creating booking');
        }
      } else if (stripeSetup.error) {
        throw new Error(
          stripeSetup.error.decline_code || stripeSetup.error.code || stripeSetup.error.type,
        );
      } else {
        throw new Error('Unknown error while creating a booking!');
      }
    },
    [touchPointId, bookingIntentId, userId, paymentProviderId, onPaymentResults, decisionId],
  );

  return { createBooking };
};
